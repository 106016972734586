import axios from 'axios';
import { UPDATE_LEVEL } from '../../constants';

export const updateLevel = (lId, data, navigate, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadUpdateLevel());
  axios
    .put(`admin/editLevel?levelId=${lId}`, data, config)
    .then((res) => {
      dispatch(setUpdateLevel(res));
      navigate(-1);
    })
    .catch((err) => {
      dispatch(errorUpdateLevel(err.response));
      onError(err.response);
    });
};

export const loadUpdateLevel = () => ({
  type: UPDATE_LEVEL.LOAD
});

export const setUpdateLevel = (data) => ({
  type: UPDATE_LEVEL.SUCCESS,
  payload: data.data
});

export const errorUpdateLevel = (error) => ({
  type: UPDATE_LEVEL.FAIL,
  payload: error
});
