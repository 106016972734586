import axios from 'axios';
import { LEVEL_LIST } from '../../constants';

export const fetchLevelList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadLevelList());

  axios
    .get('/admin/listLevel', config)
    .then((res) => dispatch(setLevelList(res)))
    .catch((err) => {
      dispatch(errorLevelList(err.response));
      onError(err.response);
    });
};

export const loadLevelList = () => ({
  type: LEVEL_LIST.LOAD
});

export const setLevelList = (levelList) => ({
  type: LEVEL_LIST.SUCCESS,
  payload: levelList.data.data
});

export const errorLevelList = (error) => ({
  type: LEVEL_LIST.FAIL,
  payload: error
});
