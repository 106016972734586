import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Card, Stack, Button, Container, Typography, Box, Grid } from '@mui/material';
// components
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { getErrorMessage } from '../utils/appUtils';
import Textbox from '../components/Textbox';
import Page from '../components/Page';
import { createNotification } from '../actions/adminActions/addNotification';
import TextArea from '../components/TextArea';

function AddNotification(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  const createNotification = () => {
    const data = {
      title,
      body
    };
    props.createNotification(data, (err) => onError(err));
  };

  const saveDisabled = () => {
    if (!title || !body || props.addNotificationLoading) {
      return true;
    }
    return false;
  };

  const topicInput = (inputId, inputName, value, onchange, placeholder, type) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '10px 0px' }}>
      <Grid container direction="column">
        <Grid item container alignItems="center">
          <Grid item xs={inputId === 'introPrompt' ? 12 : 4}>
            <Typography variant="body1">{inputName}</Typography>
          </Grid>
          <Grid item xs={8}>
            {inputId === 'body' ? (
              <TextArea
                id={inputId}
                value={value}
                onChange={onchange}
                placeholder={placeholder}
                type={type}
              />
            ) : (
              <Textbox
                id={inputId}
                value={value}
                onChange={onchange}
                placeholder={placeholder}
                type={type}
                fullWidth
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Page title="Add Notification | ExcerptLab-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Add New Notification
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Stack>
        <Card style={{ display: 'flex', justifyContent: 'center', padding: '15px 10px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 500
            }}
          >
            {topicInput('title', 'Title', title, (e) => setTitle(e.target.value), 'Title')}
            {topicInput('body', 'Body', body, (e) => setBody(e.target.value), 'Body')}
            <LoadingButton
              variant="contained"
              style={{ width: 200, padding: 10, marginTop: 10 }}
              disabled={saveDisabled()}
              loading={props.addNotificationLoading}
              onClick={() => createNotification()}
            >
              Save
            </LoadingButton>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  addNotificationData: state.addNotificationData.data,
  addNotificationLoading: state.addNotificationData.loading,
  addNotificationError: state.addNotificationData.error
});
export default connect(mapStateToProps, {
  createNotification
})(AddNotification);
