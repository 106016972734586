import axios from 'axios';
import { ADD_LEVEL } from '../../constants';

export const createLevel = (data, navigate, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(addLevel());
  axios
    .post(`admin/createLevel`, data, config)
    .then((res) => {
      dispatch(setLevel(res));
      navigate('/dashboard/level');
    })
    .catch((err) => {
      dispatch(errorLevel(err.response));
      onError(err.response);
    });
};

export const addLevel = () => ({
  type: ADD_LEVEL.LOAD
});

export const setLevel = (data) => ({
  type: ADD_LEVEL.SUCCESS,
  payload: data.data
});

export const errorLevel = (error) => ({
  type: ADD_LEVEL.FAIL,
  payload: error
});
