import axios from 'axios';
import { DELETE_INFORMATION } from '../../constants';

export const deleteInformation = (hID, navigateTo, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadDeleteInformation());
  axios
    .delete(`/admin/deleteInformation/${hID}`, config)
    .then((res) => {
      dispatch(setDeleteInformation(res));
      navigateTo();
    })
    .catch((err) => {
      dispatch(errorDeleteInformation(err.response));
      onError(err.response);
    });
};

export const loadDeleteInformation = () => ({
  type: DELETE_INFORMATION.LOAD
});

export const setDeleteInformation = (data) => ({
  type: DELETE_INFORMATION.SUCCESS,
  payload: data
});

export const errorDeleteInformation = (error) => ({
  type: DELETE_INFORMATION.FAIL,
  payload: error
});
