import axios from 'axios';
import { QUESTION_ORDER } from '../../constants';

export const questionOrder = (data, navigate, topicId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadQuestionOrder());
  axios
    .put(`/admin/updateQuestionOrder`, data, config)
    .then((res) => {
      dispatch(setQuestionOrder(res));
      navigate('/dashboard/question', {
        state: { topicId }
      });
    })
    .catch((err) => {
      dispatch(errorQuestionOrder(err.response));
      onError(err.response);
    });
};

export const loadQuestionOrder = () => ({
  type: QUESTION_ORDER.LOAD
});

export const setQuestionOrder = (data) => ({
  type: QUESTION_ORDER.SUCCESS,
  payload: data.data
});

export const errorQuestionOrder = (error) => ({
  type: QUESTION_ORDER.FAIL,
  payload: error
});
