import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

// material
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions
} from '@mui/material';
// components
import { Form, FormikProvider, useFormik } from 'formik';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from '../../utils/appUtils';
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';
import { changePassword } from '../../actions/adminActions/changePassword';
import { logoutAdmin } from '../../actions/adminActions/adminLogin';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/'
  },
  {
    label: 'Change Password',
    icon: 'mdi:lock-reset',
    linkTo: '/'
  }
  // {
  //   label: 'Profile',
  //   icon: 'eva:person-fill',
  //   linkTo: '#'
  // },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  //   linkTo: '#'
  // }
];

// ----------------------------------------------------------------------

function AccountPopover(props) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpenChangePassword(false);
    resetForm();
    setOpen(false);
  };

  const ChangePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Required'),
    newPassword: Yup.string().required('Required'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], "Passwords don't match!")
      .required('Required')
  });

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  const onPressChangePassword = (values) => {
    try {
      props.changePassword(
        values,
        () => {
          handleClose();
          props.logoutAdmin(navigate);
        },
        (err) => onError(err)
      );
    } catch (e) {
      console.log('eee', e, values);
    }
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: ChangePasswordSchema,

    onSubmit: (values) => {
      onPressChangePassword(values);
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps, resetForm } = formik;
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {localStorage.getItem('userName')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {localStorage.getItem('userEmail')}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={() => {
              if (option.label === 'Change Password') {
                setOpenChangePassword(true);
              } else {
                handleClose();
              }
            }}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Iconify
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => {
              handleClose();
              props.logout();
            }}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
      <Dialog open={openChangePassword} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Change Password</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <div
                style={{
                  marginTop: '10px'
                }}
              >
                <TextField
                  fullWidth
                  label="Current Password"
                  {...getFieldProps('currentPassword')}
                  error={Boolean(touched.currentPassword && errors.currentPassword)}
                  helperText={touched.currentPassword && errors.currentPassword}
                />
              </div>
              <div
                style={{
                  marginTop: '10px'
                }}
              >
                <TextField
                  fullWidth
                  label="New Password"
                  {...getFieldProps('newPassword')}
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  helperText={touched.newPassword && errors.newPassword}
                />
              </div>
              <div
                style={{
                  marginTop: '10px'
                }}
              >
                <TextField
                  fullWidth
                  label="Confirm New Password"
                  {...getFieldProps('confirmNewPassword')}
                  error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
                  helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" variant="contained" disabled={props.changePasswordDataLoading}>
                Save
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  changePasswordData: state.changePasswordData.data,
  changePasswordDataLoading: state.changePasswordData.loading
});

export default connect(mapStateToProps, {
  changePassword,
  logoutAdmin
})(AccountPopover);
