// material
import { Container, Stack, Typography, Button, Box } from '@mui/material';
import { useState } from 'react';
// components
import { useLocation, useNavigate } from 'react-router-dom';
import { Draggable, moveItems, useDraggable, useDraggableContext } from 'react-tiny-dnd';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from '../utils/appUtils';
import Page from '../components/Page';
import { questionOrder } from '../actions/adminActions/questionOrder';

function Item(props) {
  const { id, listeners, isDragging, item, index } = props;
  const opacity = isDragging ? 0.5 : 1;

  return (
    <div key={index} style={{ opacity, backgroundColor: 'white' }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#edf1f7',
          marginTop: 20
        }}
      >
        <div
          {...listeners}
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: 10
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 0.95
            }}
          >
            {index + 1}
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                marginLeft: 20
              }}
            >
              <p style={{ fontWeight: 'bold' }}>Question</p>
              {item.questionTitle}
            </div>
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
            <p style={{ fontWeight: 'bold' }}>Question Type</p>
            <div>{item.type}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DraggableItem(props) {
  const { context, item, index } = props;
  const { listeners, isDragging } = useDraggable(context, index);

  return (
    <Draggable
      context={context}
      key={item._id}
      index={index}
      {...{
        preview: (
          <div style={{ width: 800, backgroundColor: '#808080' }}>
            <Item
              id={item._id}
              item={item}
              listeners={listeners}
              isDragging={false}
              index={index}
            />
          </div>
        )
      }}
    >
      <Item id={item._id} item={item} listeners={listeners} isDragging={isDragging} index={index} />
    </Draggable>
  );
}

function ChangeQuestionOrder(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [items, setItems] = useState(location.state.filteredQuestions);
  const topicId = location?.state?.topicId;

  const onDrop = (dragIndex, overIndex) => {
    const nextItems = moveItems(items, dragIndex, overIndex);
    setItems(nextItems);
  };

  const context = useDraggableContext({
    onDrop
  });

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  const handleSave = () => {
    console.log('item', items);
    const newData = items.map((e, i) => ({ questionId: e._id, index: i }));
    const data = { topicId, questionList: newData };
    console.log('newData', data);
    props.questionOrder(data, navigate, topicId, (err) => onError(err));
  };

  return (
    <Page title="Dashboard: Change Question Order | ExcerptLab-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Change Question Order
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              navigate('/dashboard/question', { state: { topicId } });
            }}
          >
            Back
          </Button>
        </Stack>
        <Box
          sx={{
            justifyContent: 'center',
            width: '100%',
            margin: '10px 0px',
            backgroundColor: '#fff',
            padding: 3,
            borderRadius: 2
          }}
        >
          <div>
            {items.map((item, i) => (
              <DraggableItem context={context} index={i} item={item} key={item.id} />
            ))}
          </div>
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
          >
            <Button
              variant="contained"
              style={{ width: 200, padding: 10, marginTop: 20 }}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </div>
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
  questionOrder
})(ChangeQuestionOrder);
