import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import { Card, Stack, Button, Container, Typography, Box, Grid } from '@mui/material';
// components

import { LoadingButton } from '@mui/lab';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import Page from '../components/Page';
import Textbox from '../components/Textbox';
import CommonCheckbox from '../components/CommonCheckbox';
import { updateUser } from '../actions/adminActions/updateUser';
import { getErrorMessage } from '../utils/appUtils';

function EditUser(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [userName, setUserName] = useState(state?.name);
  const [email, setEmail] = useState(state?.email);
  const [isTestUser, setIsTestUser] = useState(state?.isTestUser);
  const [isPremium, setIsPremium] = useState(state?.isPremium);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  const handleSave = () => {
    const data = {};
    if (state.name !== userName) data.name = userName;
    if (state.email !== email) data.email = email;
    if (state.isTestUser !== isTestUser) data.isTestUser = isTestUser;
    if (state.isPremium !== isPremium) data.isPremium = isPremium;

    props.updateUser(state?._id, data, navigate, (err) => onError(err));
  };

  const topicInput = (inputId, inputName, value, onchange, placeholder, disabled) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '10px 0px' }}>
      <Grid container direction="column">
        <Grid item container alignItems="center">
          <Grid item xs={inputId === 'introPrompt' ? 12 : 4}>
            <Typography variant="body1">{inputName}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Textbox
              id={inputId}
              value={value}
              onChange={onchange}
              placeholder={placeholder}
              fullWidth
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
  return (
    <Page title="Edit User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit User
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Stack>
        <Card style={{ display: 'flex', justifyContent: 'center', padding: '15px 10px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 500
            }}
          >
            {topicInput('email', 'Email', email, (e) => setEmail(e.target.value), 'Email', true)}
            {topicInput('name', 'Name', userName, (e) => setUserName(e.target.value), 'Name')}
            <Grid item container alignItems="center">
              <Grid item xs={4}>
                <Typography variant="body1">Premium User</Typography>
              </Grid>
              <CommonCheckbox
                id="isPremiumUser"
                checked={isPremium}
                onChange={(e) => {
                  setIsPremium(e.target.checked);
                }}
              />
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={4}>
                <Typography variant="body1">Test User</Typography>
              </Grid>
              <CommonCheckbox
                id="isTestUser"
                checked={isTestUser}
                onChange={(e) => {
                  setIsTestUser(e.target.checked);
                }}
              />
            </Grid>
            <LoadingButton
              variant="contained"
              style={{ width: 200, padding: 10, marginTop: 10 }}
              onClick={() => handleSave()}
            >
              Save
            </LoadingButton>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
  updateUser
})(EditUser);
