// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill')
  // },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'level',
    path: '/dashboard/level',
    icon: getIcon('bi:123')
  },
  {
    title: 'topic',
    path: '/dashboard/topic',
    icon: getIcon('dashicons:buddicons-topics')
  },
  {
    title: 'question',
    path: '/dashboard/question',
    icon: getIcon('mdi:frequently-asked-questions')
  },
  {
    title: 'notification',
    path: '/dashboard/notification',
    icon: getIcon('material-symbols:notification-add-rounded')
  },
  {
    title: 'Feedback',
    path: '/dashboard/comments',
    icon: getIcon('fluent:person-feedback-24-filled')
  }
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill')
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill')
  // }
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill')
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill')
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill')
  // }
];

export default sidebarConfig;
