// logout dialog
export const LOGOUT = 'Logout';
export const YES = 'YES';
export const NO = 'NO';
export const LOGOUT_MESSAGE = 'Are you sure you want to logout?';

// delete user dialog
export const USER = 'User';
export const DELETE_USER_MESSAGE = 'Are you sure you want to delete user?';

// delete user dialog
export const TOPIC = 'Topic';
export const DELETE_TOPIC_MESSAGE = 'Are you sure you want to delete topic?';

// delete user dialog
export const QUESTION = 'Question';
export const DELETE_QUESTION_MESSAGE = 'Are you sure you want to delete question?';

// questions
export const NO_TOPICS_FOUND = 'No topics found, please add topics!';
export const SELECT_TOPIC = 'Please select topic!';
export const NO_QUESTIONS_FOUND = 'No questions found, please add questions!';

// user
export const NO_USERS_FOUND = 'No users found, please add users!';

// addEditQuesion
export const PROMPT = 'Prompt';
export const DELETE_PROMPT_COMPONENT_MESSAGE = 'Are you sure you want to delete prompt component?';

// comments
export const NO_COMMENTS_FOUND = 'No comments found!';
export const COMMENT = 'Comment';
export const DELETE_COMMENT_MESSAGE = 'Are you sure you want to delete comment?';

// hint
export const HINT = 'Hint';
export const INFORMATION = 'INFORMATION';
export const DELETE_HINT_MESSAGE = 'Are you sure you want to delete hint?';
export const DELETE_INFORMATION_MESSAGE = 'Are you sure you want to delete information?';

// notification
export const NO_NOTIFICATION_FOUND = 'No notifications found!';

// level
export const NO_LEVEL_FOUND = 'No levels found!';

// delete level dialog
export const LEVEL = 'Level';
export const DELETE_LEVEL_MESSAGE = 'Are you sure you want to delete level?';
