import axios from 'axios';
import { DELETE_LEVEL } from '../../constants';

export const deleteLevel = (delID, setDeleteDialog, onError) => (dispatch) => {
  const token = localStorage.getItem('jwtToken');
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadDelLevel());
  axios
    .delete(`/admin/deleteLevel?levelId=${delID}`, config)
    .then((res) => {
      dispatch(successDelLevel(res));
      setDeleteDialog(false);
    })
    .catch((err) => {
      dispatch(failDelLevel(err));
      onError(err.response);
    });
};

export const loadDelLevel = () => ({
  type: DELETE_LEVEL.LOAD
});

export const successDelLevel = (level) => ({
  type: DELETE_LEVEL.SUCCESS,
  payload: level
});

export const failDelLevel = (err) => ({
  type: DELETE_LEVEL.FAIL,
  payload: err
});
