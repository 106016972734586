import axios from 'axios';
import { ADD_INFORMATION } from '../../constants';

export const createInformation = (data, tID, navigate, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(addInformation());
  axios
    .post(`admin/createInformation`, data, config)
    .then((res) => {
      dispatch(setInformation(res));
      navigate('/dashboard/question', {
        state: { topicId: tID }
      });
      if (res.data.status === 200) {
        onSuccess();
      }
    })
    .catch((err) => {
      dispatch(errorInformation(err.response));
      onError(err.response);
    });
};

export const addInformation = () => ({
  type: ADD_INFORMATION.LOAD
});

export const setInformation = (data) => ({
  type: ADD_INFORMATION.SUCCESS,
  payload: data.data
});

export const errorInformation = (error) => ({
  type: ADD_INFORMATION.FAIL,
  payload: error
});
