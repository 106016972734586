import axios from 'axios';
import { INFORMATION_LIST } from '../../constants';

export const fetchInformationList = (qId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadInformationList());

  axios
    .get(`/admin/listInformation/${qId}`, config)
    .then((res) => dispatch(setInformationList(res)))
    .catch((err) => {
      dispatch(errorInformationList(err.response));
      onError(err.response);
    });
};

export const loadInformationList = () => ({
  type: INFORMATION_LIST.LOAD
});

export const setInformationList = (informationList) => ({
  type: INFORMATION_LIST.SUCCESS,
  payload: informationList.data.data
});

export const errorInformationList = (error) => ({
  type: INFORMATION_LIST.FAIL,
  payload: error
});
