import axios from 'axios';
import { ADD_NOTIFICATION } from '../../constants';

export const createNotification = (data, navigate, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(addNotification());
  axios
    .post(`admin/createAdminNotification`, data, config)
    .then((res) => {
      dispatch(setNotification(res));
      navigate('/dashboard/notification');
    })
    .catch((err) => {
      dispatch(errorNotification(err.response));
      onError(err.response);
    });
};

export const addNotification = () => ({
  type: ADD_NOTIFICATION.LOAD
});

export const setNotification = (data) => ({
  type: ADD_NOTIFICATION.SUCCESS,
  payload: data.data
});

export const errorNotification = (error) => ({
  type: ADD_NOTIFICATION.FAIL,
  payload: error
});
