import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import { Card, Stack, Button, Container, Typography, Box, Grid } from '@mui/material';
// components
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { getErrorMessage, numberArray } from '../utils/appUtils';
import Textbox from '../components/Textbox';
import Page from '../components/Page';
import { createLevel } from '../actions/adminActions/addLevel';
import Dropdown from '../components/Dropdown';
import { updateLevel } from '../actions/adminActions/updateLevel';

function AddLevel(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [level, setLevel] = useState('1');
  const [levelName, setLevelName] = useState('');

  const { state, pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/dashboard/edit/level' && state) {
      const { level, levelName } = state;
      setLevel(level);
      setLevelName(levelName);
    }
    if (pathname === '/dashboard/edit/level' && !state) {
      navigate('/dashboard/level');
    }
  }, []);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  const createLevel = () => {
    const data = {
      level,
      levelName
    };
    props.createLevel(data, (err) => onError(err));
  };

  const updateLevel = () => {
    const data = {};
    if (state.level !== level) data.level = level;
    if (state.levelName !== levelName) data.levelName = levelName;
    props.updateLevel(state._id, data, (err) => onError(err));
  };

  const handleSave = () => {
    if (pathname === '/dashboard/edit/level') {
      updateLevel();
    } else createLevel();
  };

  const saveDisabled = () => {
    const disableSave = !level || !levelName;
    if (pathname === '/dashboard/edit/level' && (disableSave || props.updateLevelLoading)) {
      return true;
    }
    if (pathname === '/dashboard/add/level' && (disableSave || props.addLevelLoading)) {
      return true;
    }
    return false;
  };

  const topicInput = (inputId, inputName, value, onchange, placeholder, type, inputArray) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '10px 0px' }}>
      <Grid container direction="column">
        <Grid item container alignItems="center">
          <Grid item xs={inputId === 'introPrompt' ? 12 : 4}>
            <Typography variant="body1">{inputName}</Typography>
          </Grid>
          <Grid item xs={8}>
            {type === 'select' ? (
              <Dropdown itemsArray={inputArray} selectedItem={value} handleChange={onchange} />
            ) : (
              <Textbox
                id={inputId}
                value={value}
                onChange={onchange}
                placeholder={placeholder}
                type={type}
                fullWidth
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  console.log('pathname', pathname);

  return (
    <Page
      title={`${pathname === '/dashboard/edit/level' ? 'Edit Level' : 'Add Level'} | ExcerptLab-UI`}
    >
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {pathname === '/dashboard/edit/level' ? 'Edit Level' : 'Add New Level'}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Stack>
        <Card style={{ display: 'flex', justifyContent: 'center', padding: '15px 10px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 500
            }}
          >
            {topicInput(
              'level',
              'Level',
              level,
              (e) => setLevel(e.target.value),
              'Level',
              'select',
              numberArray
            )}
            {topicInput(
              'levelName',
              'Level Name',
              levelName,
              (e) => setLevelName(e.target.value),
              'Level Name'
            )}
            <LoadingButton
              variant="contained"
              style={{ width: 200, padding: 10, marginTop: 10 }}
              disabled={saveDisabled()}
              loading={
                pathname === '/dashboard/edit/level'
                  ? props.updateLevelLoading
                  : props.addLevelLoading
              }
              onClick={() => handleSave()}
            >
              Save
            </LoadingButton>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  addLevelData: state.addLevelData.data,
  addLevelLoading: state.addLevelData.loading,

  updateLevelLoading: state.updateLevelData.loading
});
export default connect(mapStateToProps, {
  createLevel,
  updateLevel
})(AddLevel);
