// material
import { Container, Stack, Typography, Button, Box } from '@mui/material';
import React, { useState } from 'react';
// components
import { useLocation, useNavigate } from 'react-router-dom';
import { Draggable, moveItems, useDraggable, useDraggableContext } from 'react-tiny-dnd';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from '../utils/appUtils';
import AudioPlayer from '../components/AudioPlayer';
import Page from '../components/Page';
import { updateHint } from '../actions/adminActions/updateHint';
import { updateInformation } from '../actions/adminActions/updateInformation';
import { updateTopic } from '../actions/adminActions/updateTopic';

function Item(props) {
  const { listeners, isDragging, item, index } = props;
  const opacity = isDragging ? 0.5 : 1;
  return (
    <div key={index} style={{ opacity, backgroundColor: 'white' }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#edf1f7',
          marginTop: 20
        }}
      >
        <div
          {...listeners}
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            padding: 10
          }}
        >
          <div
            style={{
              display: 'flex'
            }}
          >
            {index + 1}
            <div
              style={{
                marginLeft: 20,
                width: item.type === 'audio' ? 500 : null
              }}
            >
              {item.type === 'image' && (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  style={{ width: 200, height: 150, borderRadius: 8, objectFit: 'contain' }}
                  src={item.value}
                />
              )}
              {item.type === 'text' && <div>{ReactHtmlParser(item.value)}</div>}
              {item.type === 'audio' && <AudioPlayer audioUrl={item.value} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DraggableItem(props) {
  const { context, item, index } = props;
  const { listeners, isDragging } = useDraggable(context, index);

  return (
    <Draggable
      context={context}
      key={item._id}
      index={index}
      {...{
        preview: (
          <div style={{ width: 800, backgroundColor: '#808080' }}>
            <Item
              id={item._id}
              item={item}
              listeners={listeners}
              isDragging={false}
              index={index}
            />
          </div>
        )
      }}
    >
      <Item id={item._id} item={item} listeners={listeners} isDragging={isDragging} index={index} />
    </Draggable>
  );
}

function ReorderPrompt(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const editQuestion = state?.editQuestion ?? false;
  const index = state?.index ?? null;
  const prompt = state?.prompt ?? [];
  const option = state?.option ?? [];
  const questionId = state?.questionId ?? null;
  const topicId = state?.topicId ?? null;
  const _id = state?._id ?? null;
  const editHint = state?.editHint ?? false;
  const editInformation = state?.editInformation ?? false;
  const title = state?.title ?? null;
  const level = state?.level ?? null;
  const editTopic = state?.editTopic ?? false;
  const editCorrect = state?.editCorrect ?? false;
  const type = state?.type ?? '';

  const [items, setItems] = useState(prompt);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };
  const onDrop = (dragIndex, overIndex) => {
    const nextItems = moveItems(items, dragIndex, overIndex);
    setItems(nextItems);
  };
  const context = useDraggableContext({
    onDrop
  });

  const handleSave = () => {
    if (editQuestion) {
      const newOption = option.map((e, i) => ({
        _id: e._id,
        correctAnswer: e.correctAnswer,
        option: e.option,
        prompt: i === index ? items : e.prompt
      }));
      navigate('/dashboard/edit/question', {
        state: { prompt: items, index, questionId, topicId, update: true, option: newOption }
      });
    } else if (editCorrect) {
      navigate('/dashboard/edit/question', {
        state: { prompt: items, index, questionId, topicId, title, correctPrompt: true, type }
      });
    } else if (editHint) {
      const newPrompt = items.map((pro) => {
        const prompt = {
          type: pro.type,
          value: pro.value
        };
        return prompt;
      });
      const newHintData = {
        _id,
        hintInfo: newPrompt,
        questionId
      };
      props.updateHint(_id, newHintData, topicId, (err) => onError(err));
      setTimeout(() => {
        navigate('/dashboard/hint', {
          state: { questionId, topicId, edit: true, update: true, prompt: items }
        });
      }, 1000);
    } else if (editInformation) {
      const newPrompt = items.map((pro) => {
        const prompt = {
          type: pro.type,
          value: pro.value
        };
        return prompt;
      });
      const newHintData = {
        _id,
        questionInfo: newPrompt,
        questionId
      };
      props.updateInformation(_id, newHintData, topicId, (err) => onError(err));
      setTimeout(() => {
        navigate('/dashboard/information', {
          state: { questionId, topicId, edit: true, update: true, prompt: items }
        });
      }, 1000);
    } else if (editTopic) {
      const newPrompt = items.map((pro) => {
        const prompt = {
          type: pro.type,
          value: pro.value
        };
        return prompt;
      });
      const data = { prompt: newPrompt, level };

      props.updateTopic(state._id, data, (err) => onError(err));
      setTimeout(() => {
        navigate('/dashboard/topic');
      }, 1000);
    }
  };
  return (
    <Page title="Dashboard: Reorder Prompt | ExcerptLab-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Reorder Prompt
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Stack>

        <Box
          sx={{
            justifyContent: 'center',
            width: '100%',
            margin: '10px 0px',
            backgroundColor: '#fff',
            padding: 3,
            borderRadius: 2
          }}
        >
          <div>
            {items.map((item, i) => (
              <DraggableItem context={context} index={i} item={item} key={item._id} />
            ))}
          </div>
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
          >
            <Button
              variant="contained"
              style={{ width: 200, padding: 10, marginTop: 20 }}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </div>
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  updateHintData: state.updateHintData.data,
  updateHintLoading: state.updateHintData.loading
});
export default connect(mapStateToProps, {
  updateHint,
  updateInformation,
  updateTopic
})(ReorderPrompt);
