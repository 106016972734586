import axios from 'axios';
import { NOTIFICATION_LIST } from '../../constants';

export const fetchNotificationList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  dispatch(loadNotificationList());

  axios
    .get('/admin/listAdminNotification', config)
    .then((res) => dispatch(setNotificationList(res)))
    .catch((err) => {
      dispatch(errorNotificationList(err.response));
      onError(err.response);
    });
};

export const loadNotificationList = () => ({
  type: NOTIFICATION_LIST.LOAD
});

export const setNotificationList = (notiList) => ({
  type: NOTIFICATION_LIST.SUCCESS,
  payload: notiList.data.data
});

export const errorNotificationList = (error) => ({
  type: NOTIFICATION_LIST.FAIL,
  payload: error
});
