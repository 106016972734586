import axios from 'axios';
import { UPDATE_INFORMATION } from '../../constants';

export const updateInformation =
  (iId, data, topicId, navigate, onSuccess, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    dispatch(loadUpdateInformation());
    axios
      .put(`admin/updateInformation/${iId}`, data, config)
      .then((res) => {
        dispatch(setUpdateInformation(res));
        navigate('/dashboard/question', {
          state: { topicId }
        });
        if (res.data.status === 200) {
          onSuccess();
        }
      })
      .catch((err) => {
        dispatch(errorUpdateInformation(err.response));
        onError(err.response);
      });
  };

export const loadUpdateInformation = () => ({
  type: UPDATE_INFORMATION.LOAD
});

export const setUpdateInformation = (data) => ({
  type: UPDATE_INFORMATION.SUCCESS,
  payload: data.data
});

export const errorUpdateInformation = (error) => ({
  type: UPDATE_INFORMATION.FAIL,
  payload: error
});
