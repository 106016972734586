const ADMIN_LOGIN = {
  LOAD: 'LOAD_ADMIN_LOGIN',
  SUCCESS: 'SUCCESS_ADMIN_LOGIN',
  FAIL: 'FAIL_ADMIN_LOGIN'
};

const USER_LIST = {
  LOAD: 'LOAD_USER_DATA',
  SUCCESS: 'SUCCESS_USER_DATA',
  FAIL: 'FAIL_USER_DATA'
};

const DELETE_USER = {
  LOAD: 'LOAD_DELETE_USER',
  SUCCESS: 'SUCCESS_DELETE_USER',
  FAIL: 'FAIL_DELETE_USER'
};

const UPDATE_USER = {
  LOAD: 'LOAD_UPDATE_USER',
  SUCCESS: 'SUCCESS_UPDATE_USER',
  FAIL: 'FAIL_UPDATE_USER'
};

const TOPIC_LIST = {
  LOAD: 'LOAD_TOPIC_DATA',
  SUCCESS: 'SUCCESS_TOPIC_DATA',
  FAIL: 'FAIL_TOPIC_DATA'
};

const DELETE_TOPIC = {
  LOAD: 'LOAD_DELETE_TOPIC',
  SUCCESS: 'SUCCESS_DELETE_TOPIC',
  FAIL: 'FAIL_DELETE_TOPIC'
};

const ADD_TOPIC = {
  LOAD: 'LOAD_ADD_TOPIC',
  SUCCESS: 'SUCCESS_ADD_TOPIC',
  FAIL: 'FAIL_ADD_TOPIC'
};

const UPDATE_TOPIC = {
  LOAD: 'LOAD_UPDATE_TOPIC',
  SUCCESS: 'SUCCESS_UPDATE_TOPIC',
  FAIL: 'FAIL_UPDATE_TOPIC'
};

const ADD_QUESTION = {
  LOAD: 'LOAD_ADD_QUESTION',
  SUCCESS: 'SUCCESS_ADD_QUESTION',
  FAIL: 'FAIL_ADD_QUESTION'
};

const UPDATE_QUESTION = {
  LOAD: 'LOAD_UPDATE_QUESTION',
  SUCCESS: 'SUCCESS_UPDATE_QUESTION',
  FAIL: 'FAIL_UPDATE_QUESTION'
};

const QUESTION_LIST = {
  LOAD: 'LOAD_QUESTION_DATA',
  SUCCESS: 'SUCCESS_QUESTION_DATA',
  FAIL: 'FAIL_QUESTION_DATA'
};

const DELETE_QUESTION = {
  LOAD: 'LOAD_DELETE_QUESTION',
  SUCCESS: 'SUCCESS_DELETE_QUESTION',
  FAIL: 'FAIL_DELETE_QUESTION'
};

const GET_QUESTION = {
  LOAD: 'LOAD_GET_QUESTION',
  SUCCESS: 'SUCCESS_GET_QUESTION',
  FAIL: 'FAIL_GET_QUESTION'
};

const UPLOAD_FILE = {
  LOAD: 'LOAD_UPLOAD_FILE',
  SUCCESS: 'SUCCESS_UPLOAD_FILE',
  FAIL: 'FAIL_UPLOAD_FILE',
  CLEAR: 'CLEAR_UPLOAD_FILE'
};
const DELETE_FILE = {
  LOAD: 'LOAD_DELETE_FILE',
  SUCCESS: 'SUCCESS_DELETE_FILE',
  FAIL: 'FAIL_DELETE_FILE'
};

const DISCUSSION_LIST = {
  LOAD: 'LOAD_DISCUSSION_LIST',
  SUCCESS: 'SUCCESS_DISCUSSION_LIST',
  FAIL: 'FAIL_DISCUSSION_LIST'
};

const DELETE_DISCUSS = {
  LOAD: 'LOAD_DELETE_DISCUSS',
  SUCCESS: 'SUCCESS_DELETE_DISCUSS',
  FAIL: 'FAIL_DELETE_DISCUSS'
};

const HINT_LIST = {
  LOAD: 'LOAD_HINT_DATA',
  SUCCESS: 'SUCCESS_HINT_DATA',
  FAIL: 'FAIL_HINT_DATA'
};

const ADD_HINT = {
  LOAD: 'LOAD_ADD_HINT',
  SUCCESS: 'SUCCESS_ADD_HINT',
  FAIL: 'FAIL_ADD_HINT'
};

const ADD_NOTIFICATION = {
  LOAD: 'LOAD_ADD_NOTIFICATION',
  SUCCESS: 'SUCCESS_ADD_NOTIFICATION',
  FAIL: 'FAIL_ADD_NOTIFICATION'
};

const ADD_LEVEL = {
  LOAD: 'LOAD_ADD_LEVEL',
  SUCCESS: 'SUCCESS_ADD_LEVEL',
  FAIL: 'FAIL_ADD_LEVEL'
};

const DELETE_HINT = {
  LOAD: 'LOAD_DELETE_HINT',
  SUCCESS: 'SUCCESS_DELETE_HINT',
  FAIL: 'FAIL_DELETE_HINT'
};

const UPDATE_HINT = {
  LOAD: 'LOAD_UPDATE_HINT',
  SUCCESS: 'SUCCESS_UPDATE_HINT',
  FAIL: 'FAIL_UPDATE_HINT'
};

const INFORMATION_LIST = {
  LOAD: 'LOAD_INFORMATION_DATA',
  SUCCESS: 'SUCCESS_INFORMATION_DATA',
  FAIL: 'FAIL_INFORMATION_DATA'
};

const ADD_INFORMATION = {
  LOAD: 'LOAD_ADD_INFORMATION',
  SUCCESS: 'SUCCESS_ADD_INFORMATION',
  FAIL: 'FAIL_ADD_INFORMATION'
};

const DELETE_INFORMATION = {
  LOAD: 'LOAD_DELETE_INFORMATION',
  SUCCESS: 'SUCCESS_DELETE_INFORMATION',
  FAIL: 'FAIL_DELETE_INFORMATION'
};

const UPDATE_INFORMATION = {
  LOAD: 'LOAD_UPDATE_INFORMATION',
  SUCCESS: 'SUCCESS_UPDATE_INFORMATION',
  FAIL: 'FAIL_UPDATE_INFORMATION'
};

const NOTIFICATION_LIST = {
  LOAD: 'LOAD_NOTIFICATION_DATA',
  SUCCESS: 'SUCCESS_NOTIFICATION_DATA',
  FAIL: 'FAIL_NOTIFICATION_DATA'
};

const LEVEL_LIST = {
  LOAD: 'LOAD_LEVEL_DATA',
  SUCCESS: 'SUCCESS_LEVEL_DATA',
  FAIL: 'FAIL_LEVEL_DATA'
};

const DELETE_LEVEL = {
  LOAD: 'LOAD_DELETE_LEVEL',
  SUCCESS: 'SUCCESS_DELETE_LEVEL',
  FAIL: 'FAIL_DELETE_LEVEL'
};

const UPDATE_LEVEL = {
  LOAD: 'LOAD_UPDATE_LEVEL',
  SUCCESS: 'SUCCESS_UPDATE_LEVEL',
  FAIL: 'FAIL_UPDATE_LEVEL'
};

const QUESTION_ORDER = {
  LOAD: 'LOAD_QUESTION_ORDER',
  SUCCESS: 'SUCCESS_QUESTION_ORDER',
  FAIL: 'FAIL_QUESTION_ORDER'
};

const CHANGE_PASSWORD = {
  LOAD: 'LOAD_CHANGE_PASSWORD',
  SUCCESS: 'SUCCESS_CHANGE_PASSWORD',
  FAIL: 'FAIL_CHANGE_PASSWORD'
};

export {
  ADMIN_LOGIN,
  USER_LIST,
  DELETE_USER,
  UPDATE_USER,
  TOPIC_LIST,
  DELETE_TOPIC,
  QUESTION_LIST,
  DELETE_QUESTION,
  ADD_TOPIC,
  UPDATE_TOPIC,
  ADD_QUESTION,
  GET_QUESTION,
  UPDATE_QUESTION,
  UPLOAD_FILE,
  DELETE_FILE,
  DISCUSSION_LIST,
  DELETE_DISCUSS,
  HINT_LIST,
  ADD_HINT,
  DELETE_HINT,
  UPDATE_HINT,
  INFORMATION_LIST,
  ADD_INFORMATION,
  DELETE_INFORMATION,
  UPDATE_INFORMATION,
  ADD_NOTIFICATION,
  NOTIFICATION_LIST,
  LEVEL_LIST,
  ADD_LEVEL,
  DELETE_LEVEL,
  UPDATE_LEVEL,
  QUESTION_ORDER,
  CHANGE_PASSWORD
};
