import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Box
} from '@mui/material';
// components
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { getErrorMessage } from '../utils/appUtils';
import { NO_NOTIFICATION_FOUND } from '../utils/strings';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { fetchNotificationList } from '../actions/adminActions/notificationList';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'body', label: 'Body', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (noti) =>
        noti.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        noti.body.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function Notification(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [notificationListData, setNotificationListData] = useState([]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    props.fetchNotificationList((err) => onError(err));
  }, []);

  useEffect(() => {
    const data = props.notificationListData ? props.notificationListData : [];
    setNotificationListData(data);
  }, [props.notificationListData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - notificationListData.length) : 0;

  const filteredNotification = applySortFilter(
    notificationListData,
    getComparator(order, orderBy),
    filterName
  );

  const isNotificationNotFound = filteredNotification.length === 0;

  return (
    <Page title="Notification | ExcerptLab-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Notification
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/add/notification"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Notification
          </Button>
        </Stack>
        {props.notificationListLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <div>
            {notificationListData.length > 0 ? (
              <Card>
                <UserListToolbar
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  searchPlaceholder="Search notification..."
                />

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={notificationListData.length}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {filteredNotification
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            const { _id, title, body, createdAt } = row;

                            return (
                              <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                                <TableCell component="th" scope="row">
                                  {title}
                                </TableCell>
                                <TableCell align="left">{body}</TableCell>
                                <TableCell align="left">
                                  {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isNotificationNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={notificationListData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h6" align="center">
                  {NO_NOTIFICATION_FOUND}
                </Typography>
              </Box>
            )}
          </div>
        )}
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  notificationListData: state.notificationListData.data,
  notificationListLoading: state.notificationListData.loading
});
export default connect(mapStateToProps, {
  fetchNotificationList
})(Notification);
